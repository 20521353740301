import http from "../../api/axios";
import { INotificationQuery } from "../../model/notifications";

class NotificationService {
  getData = (params: INotificationQuery): Promise<any> =>
    http.get("/notifications", { params });

  getOrderIssuesData = (order: number): Promise<any> =>
    http.get(`/notifications/${order}`);

  updateNotification = (data: any, id: number): Promise<any> =>
    http.put(`/notifications/update/${id}`, data);

  deleteNotification = (id: number): Promise<any> =>
    http.delete(`/notifications/delete/${id}`);
}

export default NotificationService;
