import { isArray, xor } from "lodash";

export const toggleLoading = (loading: any[], toggle: any[] | any) => {
  return xor(loading, isArray(toggle) ? toggle : [toggle]);
};

export const a2e = (s: string) =>
  s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString());

export const getpage = (page: number, pageSize: number) => {
  return page / pageSize;
};
export const nextPage = (page: number, pageSize: number) => {
  return page * pageSize;
};

export const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export function formatPhoneNumber(phoneNumber: string) {
  const cleanNum = phoneNumber.toString().replace(/\D/g, "");
  const match = cleanNum.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return cleanNum;
}

export const filterOptionsFirstThreeWords = (
  inputValue: string,
  option: any
) => {
  // Check if the input value length is 3 or more
  if (inputValue.length >= 3) {
    // Implement your custom filtering logic here
    // For example, check if the option label includes the input value
    return option?.label?.toLowerCase().includes(inputValue.toLowerCase());
  }
  // If the input value length is less than 3, don't filter
  return true;
};

export function generateColors(numColors: number) {
  const colors = [];
  const colorMap: any = {};
  for (let i = 0; i < numColors; i++) {
    let color;
    do {
      color = `#${Math.floor(Math.random() * 16777215).toString(16)}`; // Generate random hex color
    } while (colorMap[color]); // Ensure unique color
    colors.push(color);
    colorMap[color] = true;
  }
  return colors;
}
