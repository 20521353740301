import http from "../../api/axios";

class AuthService {
  login = (data: any): Promise<any> => http.post("/login", data);
  logout = (data: any): Promise<any> => http.post("/auth/logout");

  me = () => http.get("/auth/me");

  changePassword = (data: any): Promise<any> =>
    http.post("/auth/change-password", data);

  updateProfile = (data: any): Promise<any> => http.put("/auth/me", data);
}

export default AuthService;
