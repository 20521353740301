import http from "../../api/axios";
import { IPagination } from "../../model/pagination";

class OrderIssuesService {
  getData = (params: IPagination): Promise<any> =>
    http.get("/issues", { params });

  createOrderIssue = (data: any): Promise<any> =>
    http.post("/issues/create", data);

  updateOrderIssue = (data: any, id: number): Promise<any> =>
    http.put(`/issues/update/${id}`, data);

  deleteOrderIssue = (id: number): Promise<any> =>
    http.delete(`/issues/delete/${id}`);
}

export default OrderIssuesService;
