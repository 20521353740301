import axios from "axios";
import { ACCESS_TOKEN } from "../utils/helpers/constants";
import eventManager from "../utils/event";
import axiosRetry from 'axios-retry'


const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL || "",
  timeout: 30000, // request timeout,
  headers: {
    "Content-Type": "application/json",
    Acceept: "application/json",
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
  transitional: {
    clarifyTimeoutError: true
  }
});

http.interceptors.request.use(
  (config: any) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      ACCESS_TOKEN
    )}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log("error", error);

    if (error.code === 'ECONNABORTED') {
      console.error('The request timed out.');
    } else if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        eventManager.emit("unauthorized");
      }
    } else {
      console.error('An error occurred:', error.message);
    }

    throw error;
  }
);
axiosRetry(http, { retries: 3 });

export default http;
