import http from "../../api/axios";

class VipService {
  getData = (clientId: number): Promise<any> =>
    http.get("/vip", {
      params: {
        ClientID: clientId,
      },
    });

  getStatisticsData = (params: any): Promise<any> =>
    http.get("/vip", {
      params,
    });

  createVip = (data: any): Promise<any> => http.post("/vip/create", data);

  updateVip = (data: any, id: number): Promise<any> =>
    http.put(`/vip/update/${id}`, data);

  deleteVip = (id: number): Promise<any> => http.delete(`/vip/delete/${id}`);
}

export default VipService;
