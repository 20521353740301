import OrderIssuesService from "./OrderIssues";
import AuthService from "./auth";
import CityService from "./cities";
import ClientService from "./clients";
import EmployeeService from "./employees";
import IssueTypesService from "./issueTypes";
import NotificationService from "./notifications";
import OrdersService from "./orders";
import RegionService from "./regions";
import ReportsService from "./reports";
import SalesRepService from "./salesRep";
import ScheduleService from "./schedule";
import StatusService from "./statuses";
import VipService from "./vip";

export default class EndPoints {
  public static auth = new AuthService();
  public static region = new RegionService();
  public static city = new CityService();
  public static employee = new EmployeeService();
  public static client = new ClientService();
  public static order = new OrdersService();
  public static status = new StatusService();
  public static salesRep = new SalesRepService();
  public static vip = new VipService();
  public static reports = new ReportsService();
  public static issueType = new IssueTypesService();
  public static orderIssue = new OrderIssuesService();
  public static notification = new NotificationService();
  public static schedule = new ScheduleService();
}
