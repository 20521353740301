import http from "../../api/axios";
import {
  IAuditQuery,
  IClientOrdersQuery,
  IClientSummaryQuery,
} from "../../model/reports";

class ReportsService {
  getClientSummaryData = (params: IClientSummaryQuery): Promise<any> =>
    http.get("/reports/client-summary", { params });

  getDeliveryPercentData = (params: IClientSummaryQuery): Promise<any> =>
    http.get("/reports/DeliveryPercent", { params });

  getClientOrdersData = (params: IClientOrdersQuery): Promise<any> =>
    http.get("/reports/client-orders", { params });

  getSalesRepOrdersData = (params: IClientSummaryQuery): Promise<any> =>
    http.get("/reports/salesrep-orders", { params });

  getAuditData = (params: IAuditQuery): Promise<any> =>
    http.get("/reports/Audit", { params });
}

export default ReportsService;
