import http from "../../api/axios";

class ScheduleService {
  getSchedules = (): Promise<any> => http.get("/schedule");

  schedulesGenerate = (): Promise<any> => http.get("/reports/schedule");

  schedulePrint = (token: string): Promise<any> =>
    http.get(`/reports/schedule/pdf?token=${token}`);

  updateSechedule = (id: number, data: any): Promise<any> =>
    http.put(`/schedule/update/${id}`, data);
}

export default ScheduleService;
