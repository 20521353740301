import http from "../../api/axios";
import { IOrderQuery } from "../../model/orders/query";

class OrdersService {
  getData = (params: IOrderQuery): Promise<any> =>
    http.get("/Orders", { params });

  getOrderBarcode = (barcode: string): Promise<any> =>
    http.get(`/orders/barcode/${barcode}`);

  getOrderById = (id: number): Promise<any> => http.get(`/orders/${id}`);

  createOrder = (data: any): Promise<any> => http.post("/Orders/create", data);

  updateOrder = (data: any, id: number): Promise<any> =>
    http.put(`/Orders/update/${id}`, data);

  deleteOrder = (id: number): Promise<any> =>
    http.delete(`/Orders/delete/${id}`);

  solveOrderIssue = (data: any, issueId: number): Promise<any> =>
    http.put(`/issues/update/${issueId}`, data);
}

export default OrdersService;
