import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./contexts/auth/provider";
import AppContextProvider from "./contexts/app/provider";
import { ConfigProvider, Spin } from "antd";
import arEG from "antd/locale/ar_EG";
import NotificationContextProvider from "./contexts/notifications/provider";
import "chart.js/auto"; // Register all chart types

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = React.lazy(() => import("./App"));

root.render(
  // <React.StrictMode>
  <Suspense
    fallback={
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="default" />
      </div>
    }
  >
    <BrowserRouter>
      <AuthContextProvider>
        <AppContextProvider>
          <ConfigProvider
            direction="rtl"
            locale={arEG}
            theme={{
              components: {
                DatePicker: {
                  colorLink: "#F5DEB4",
                  colorLinkHover: "#7E4F25",
                },
              },
              token: {
                colorPrimary: "#1DA1F2",
                colorTextSecondary: "#17427A",

                fontFamily: "Cairo",
              },
            }}
          >
            <NotificationContextProvider>
              <App />
            </NotificationContextProvider>
          </ConfigProvider>
        </AppContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </Suspense>
  // </React.StrictMode>
);
