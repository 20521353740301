import { useContext, useEffect, useReducer } from "react";
import { execute } from "../../utils/api/api-execute";
import { internalState } from "./context";
import reducer from "./reducer";
import EndPoints from "../../services/end-points";
import { IPagination } from "../../model/pagination";
import NotificationContext from "./context";
import AuthContext from "../auth/context";

export interface IProps {
  children: React.ReactNode;
}
const NotificationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    if (authUser) getData();
  }, [authUser]);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
        let defaultQuery = { ...state.query };
        if (authUser?.Role === "salesrep")
          defaultQuery = { ...defaultQuery, RecipientID: authUser.EmployeeID };
        if (authUser?.Role === "client")
          defaultQuery = { ...defaultQuery, RecipientID: authUser.EmployeeID };

        const { data } = await EndPoints.notification.getData(defaultQuery);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (data: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const updateNotification = async (id: number, request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });

        await EndPoints.notification.updateNotification(request, id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteNotification = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.notification.deleteNotification(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setQuery = (query: IPagination) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,

          updateNotification,
          deleteNotification,
          setQuery,
        },
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
